import React from "react"
import styled from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import { Primary, Secondary } from "../Section"
import { HrefButtonInverse } from "../Button"
import PartnershipsHero from "./PartnershipsHero"

import SvgImage from "../SvgImage"
import { BackgroundSectionStyle } from "../Section/backgroundSection.css.js"
import LogoEpsilon from "../../images/logo-epsilon.svg"
import LogoLehand from "../../images/logo-lehand.svg"
import LogoMjMd from "../../images/logo-mjmd.svg"

const StyledPartnershipsHero = styled(PartnershipsHero)`
  ${BackgroundSectionStyle};
  padding-top: 10rem;
  min-height: ${props => props.backgroundHeight}px;
  text-align: ${props => (props.centered ? "center" : "initial")};
`

const TouchlessFlatware = ({ sectionImg }) => {
  return (
    <section>
      <StyledPartnershipsHero backgroundHeight={550} centered>
        <h1>The Road to Incredible Partnerships!</h1>
      </StyledPartnershipsHero>
      <Primary>
        <Row>
          <Col xs={12} md={6}>
            <SvgImage width={350} imgSrc={LogoMjMd} altText="MJ MD Logo" />
          </Col>
          <Col xs={12} md={6}>
            <h3>MJ MD Global Management Group</h3>
            <p>
              MJ MD Global Management works in partnership with organizations
              that promote the spirit of product development and creativity. We
              believe in the spirit of freedom, truth and moral character.
            </p>
            <HrefButtonInverse href="https://www.mjmdglobal.com/">
              Take me to MJ MD Global Management
            </HrefButtonInverse>
          </Col>
        </Row>
      </Primary>
      <Secondary>
        <Row>
          <Col xs={12} md={6}>
            <SvgImage
              width={350}
              imgSrc={LogoEpsilon}
              altText="The Joyner Hand Logo"
            />
          </Col>
          <Col xs={12} md={6}>
            <h3>EPSILON LAMBDA BOULE´ FOUNDATION</h3>
            <p>
              Epsilon Lambda Boule Foundation promotes generational achievement
              through financial literacy and higher education. The Foundation
              supports the{" "}
              <a href="https://www.elbfoundation.org/program">
                Boule Scholars Program
              </a>
              . The Boule Scholars Program is administered by the Archons
              (members) of the Epsilon Lambda Member Boule of Sigma Pi Phi
              Fraternity in Palm Beach, Martin and St. Lucie Counties, Florida.
            </p>
            <HrefButtonInverse href="https://www.elbfoundation.org/">
              Take me to Epsilon Lambda Boule Foundation
            </HrefButtonInverse>
          </Col>
        </Row>
      </Secondary>
      <Primary>
        <Row>
          <Col xs={12} md={6}>
            <SvgImage
              width={350}
              imgSrc={LogoLehand}
              altText="Touchless Chopsticks logo"
            />
          </Col>
          <Col xs={12} md={6}>
            <h3>LE HAND EQUINE RACING PARTNERSHIP</h3>
            <p>
              Pets including horses can improve your health! Feel good, decrease
              your blood pressure, improve your cholesterol and your mental
              well-being with pets. Learn more about horses, pets and the racing
              partnership.
            </p>
            <HrefButtonInverse href="http://joynermd.com/contact">
              Contact Le Hand Equine Racing Partnership
            </HrefButtonInverse>
          </Col>
        </Row>
      </Primary>
    </section>
  )
}

export default TouchlessFlatware
