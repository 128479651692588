import React from "react"
import Partnerships from "../components/Partnerships"
import { Layout } from "../components/Layout"

const PartnershipsPage = () => {
  return (
    <Layout>
      <Partnerships />
    </Layout>
  )
}

export default PartnershipsPage
